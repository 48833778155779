import useTranslateFunction from "@hooks/useTranslateFunction"
import { useUser } from "hooks"
import { usePrompt } from "hooks/usePrompt"
import mobileApp, { useMobileAppHelper } from "lib/mobileApp"
import { trpc } from "lib/trpc"
import { useEffect, useState } from "react"
import semverCompare from "semver-compare"

export function AskForPushNotificationsPermission() {
  const { t } = useTranslateFunction()
  const { user } = useUser()
  const [didAskForPermissions, setDidAskForPermissions] = useState(false)
  const { openPrompt } = usePrompt()
  const { isMobileApp, confirmafyAppVersion } = useMobileAppHelper()
  const trpcUtils = trpc.useContext()
  const mutation = trpc.user.setPushNotifications.useMutation({
    onError: (err) => {
      console.error(err)
      alert(
        t(
          "AskForPushNotificationsPermission_Hubo un error al actualizar las notificaciones"
        )
      )
    },
    onSuccess: async () => {
      console.log("Successfully updated push notifications")
      await trpcUtils.user.loadUser.invalidate()
    },
  })

  useEffect(() => {
    function askForPushNotificationsPermission() {
      openPrompt({
        title: t("AskForPushNotificationsPermission_Notificaciones"),
        description: t(
          "AskForPushNotificationsPermission_¿Quieres recibir notificaciones cuando tus clientes confirmen o cancelen su cita? [RECOMENDADO]"
        ),
        onConfirm: () => mobileApp.askForPushNotificationsPermission(),
        primaryActionText: t("AskForPushNotificationsPermission_Sí"),
        secondaryActionText: t("AskForPushNotificationsPermission_No"),
        onCancel: () =>
          mutation.mutate({
            pushNotificationsEnabled: false,
            pushNotificationsState: "denied",
            expoPushNotificationToken: null,
          }),
      })
    }

    let appVersionSupportsRequestingNotificationsPermission = false

    if (confirmafyAppVersion) {
      const compare = semverCompare(confirmafyAppVersion, "1.0.6") // Push notifications are introduced in 1.0.6

      if (compare === 0 || compare === 1) {
        appVersionSupportsRequestingNotificationsPermission = true
      }
    }

    if (
      user &&
      user.pushNotificationsState === "havent_asked_for_permission" &&
      isMobileApp &&
      !didAskForPermissions &&
      appVersionSupportsRequestingNotificationsPermission &&
      user.finishedOnBoarding
    ) {
      setDidAskForPermissions(true)
      askForPushNotificationsPermission()
    }
  }, [user, isMobileApp])

  useEffect(() => {
    function handleWebViewMessage(message: any) {
      if (message.data.EXPO_PUSH_TOKEN) {
        mutation.mutate({
          expoPushNotificationToken: message.data.EXPO_PUSH_TOKEN,
          pushNotificationsEnabled: true,
          pushNotificationsState: "granted",
        })
      }
    }

    if (isMobileApp) {
      document.addEventListener("EXPO_PUSH_TOKEN", handleWebViewMessage)
    }

    return () => {
      if (isMobileApp) {
        document.removeEventListener("EXPO_PUSH_TOKEN", handleWebViewMessage)
      }
    }
  }, [isMobileApp])

  return null
}

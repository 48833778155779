import { ChevronRightIcon } from "@heroicons/react/24/outline"
import Link from "next/link"
import { useState, useCallback } from "react"
import { useCreateCalendar } from "hooks/useCreateCalendar"
import { useRouter } from "next/router"
import { MissingGoogleAccountOrPermissionsButton } from "./MissingGoogleAccountOrPermissionsButton"
import useTranslateFunction from "@hooks/useTranslateFunction"
import { useCalendars, useGoogleCalendars } from "@hooks/useCalendars"
import { Spinner } from "./Spinner"
import { ScrollArea } from "./shadcn/ui/scroll-area"

export function AddCalendarList({ closeModal }: { closeModal: () => void }) {
  const [selected, setSelected] = useState(0)
  const { t } = useTranslateFunction()

  const handleTabChange = useCallback(
    (selectedTabIndex: number) => setSelected(selectedTabIndex),
    []
  )

  const tabs = [
    {
      name: t("AddCalendarList_Calendario de Confirmafy"),
      component: <CreateConfirmafyCalendar></CreateConfirmafyCalendar>,
    },
    {
      name: t("AddCalendarList_Calendario de Google"),
      component: (
        <GoogleCalendarList closeModal={closeModal}></GoogleCalendarList>
      ),
    },
  ]

  return (
    <div>
      <ScrollArea className="h-[calc(100vh-10rem)]">
        <Tabs
          tabs={tabs}
          selected={selected}
          handleTabChange={handleTabChange}
        />
        {tabs[selected].component}
      </ScrollArea>
    </div>
  )
}

function GoogleCalendarList({ closeModal }: { closeModal: () => void }) {
  const { t } = useTranslateFunction()
  const { calendars, loading: loadingCalendars } = useCalendars()

  const {
    calendarsFromGoogle,
    loading: googleCalendarsLoading,
    missingPermissions,
    missingGoogleAccount,
  } = useGoogleCalendars()

  if (googleCalendarsLoading || loadingCalendars) {
    return (
      <div className="flex h-full items-center justify-center pb-16 pt-12">
        <Spinner />
      </div>
    )
  }

  if (missingPermissions || missingGoogleAccount) {
    return (
      <div className="mt-8 flex flex-col items-center justify-center bg-white sm:rounded-md">
        <h2 className="bold mb-10 text-xl">
          {t(
            "AddCalendarList_Necesitas conectar tu cuenta de Google y dar los permisos:"
          )}
        </h2>
        <MissingGoogleAccountOrPermissionsButton></MissingGoogleAccountOrPermissionsButton>
      </div>
    )
  }

  const options =
    calendarsFromGoogle?.filter((cal) => {
      return !calendars?.some((c) => c.googleCalendarId === cal.id)
    }) ?? []

  return (
    <div className="mt-8 bg-white sm:rounded-md">
      <h2 className="bold mb-2 text-xl">Google Calendars</h2>
      {options.length === 0 && (
        <div>
          {t(
            "AddCalendarList_No tienes más calendarios de Google que agregar. Puedes ir a"
          )}{" "}
          <a
            className=" text-blue-500"
            href="https://calendar.google.com"
            target="_blank"
          >
            Google Calendar
          </a>{" "}
          {t("AddCalendarList_y crear más calendarios.")}
        </div>
      )}
      {options.length > 0 && (
        <>
          <p className="mb-4">
            {t(
              "AddCalendarList_Estos son los calendarios que tienes en Google Calendar. Escoge el calendario donde crearás las citas:"
            )}
          </p>
          <ul role="list" className="mb-4 divide-y divide-gray-200 ">
            {options.map((calendar: any) => (
              <li key={calendar.id}>
                <Link
                  prefetch={false}
                  legacyBehavior
                  href={`/app/calendar/${calendar.id}`}
                >
                  <a className="block hover:bg-gray-50">
                    <div className="flex items-center px-2 py-4">
                      <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                        <div className="truncate">
                          <div className="flex text-sm">
                            <p className="truncate font-medium text-indigo-600">
                              {calendar.summary}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="ml-5 flex-shrink-0">
                        <ChevronRightIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </a>
                </Link>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  )
}

function CreateConfirmafyCalendar() {
  const { mostRecentUpdatedCalendar } = useCalendars()
  const router = useRouter()
  const [calendarName, setCalendarName] = useState("")
  const { createCalendar, createConfirmafyCalendarFromCopy } =
    useCreateCalendar({
      onSuccess: (calendar) => {
        router.push(`/app/calendar/${calendar.id}`)
      },
    })
  const { t } = useTranslateFunction()

  async function submit() {
    if (calendarName === "") {
      alert(t("AddCalendarList_Por favor escribe el nombre del calendario"))
      return
    }

    if (mostRecentUpdatedCalendar) {
      await createConfirmafyCalendarFromCopy({
        summary: calendarName,
        calendarFromWhichToCopy: mostRecentUpdatedCalendar,
      })
    } else {
      await createCalendar({
        summary: calendarName,
      })
    }
  }

  return (
    <div className="mt-8 flex flex-col items-center justify-center bg-white sm:rounded-md">
      <h2 className="bold mb-2 text-xl">
        {t("AddCalendarList_Crea un nuevo calendario de Confirmafy.")}
      </h2>

      <div className="relative w-full max-w-lg rounded-md">
        <input
          maxLength={36}
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault()
          }}
          type="text"
          placeholder={t("AddCalendarList_¿Nombre del calendario?")}
          className={`mb-4 block w-full min-w-0 flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm`}
          value={calendarName}
          onChange={(e) => setCalendarName(e.target.value)}
        />
      </div>
      <button
        onClick={submit}
        className="mx-auto mb-2 mt-8 block rounded-md border border-transparent bg-indigo-600 px-2 py-2 text-white hover:bg-indigo-700 "
      >
        {t("AddCalendarList_Crear calendario")}
      </button>
    </div>
  )
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ")
}

function Tabs({ tabs, handleTabChange, selected }: any) {
  return (
    <div>
      <div className="block">
        <div
          className="isolate flex divide-x divide-gray-200 rounded-lg shadow"
          aria-label="Tabs"
        >
          {tabs.map((tab, tabIdx) => (
            <button
              onClick={() => handleTabChange(tabIdx)}
              key={tab.name}
              className={classNames(
                selected === tabIdx
                  ? "text-gray-900"
                  : "text-gray-500 hover:text-gray-700",
                tabIdx === 0 ? "rounded-l-lg" : "",
                tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                "group relative min-w-0 flex-1 overflow-hidden bg-white px-4 py-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
              )}
            >
              <span>{tab.name}</span>
              <span
                aria-hidden="true"
                className={classNames(
                  selected === tabIdx ? "bg-indigo-500" : "bg-transparent",
                  "absolute inset-x-0 bottom-0 h-0.5"
                )}
              />
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}
